import _interopRequireDefault2 from "@babel/runtime/helpers/interopRequireDefault";
import _asyncSyntaxHighlighter2 from "./async-syntax-highlighter";
import _prism2 from "./async-languages/prism";
import _core from "refractor/core";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _asyncSyntaxHighlighter = _interopRequireDefault(_asyncSyntaxHighlighter2);

var _prism = _interopRequireDefault(_prism2);

var _default = (0, _asyncSyntaxHighlighter.default)({
  loader: function loader() {
    return Promise.resolve().then(function () {
      return _core;
    }).then(function (module) {
      // Webpack 3 returns module.exports as default as module, but webpack 4 returns module.exports as module.default
      return module.default || module;
    });
  },
  isLanguageRegistered: function isLanguageRegistered(instance, language) {
    return instance.registered(language);
  },
  languageLoaders: _prism.default,
  registerLanguage: function registerLanguage(instance, name, language) {
    return instance.register(language);
  }
});

exports.default = _default;
export default exports;