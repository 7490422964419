import _interopRequireDefault2 from "@babel/runtime/helpers/interopRequireDefault";
import _createLanguageAsyncLoader2 from "./create-language-async-loader";
import _abap from "refractor/lang/abap.js";
import _actionscript from "refractor/lang/actionscript.js";
import _ada from "refractor/lang/ada.js";
import _apacheconf from "refractor/lang/apacheconf.js";
import _apl from "refractor/lang/apl.js";
import _applescript from "refractor/lang/applescript.js";
import _arduino from "refractor/lang/arduino.js";
import _arff from "refractor/lang/arff.js";
import _asciidoc from "refractor/lang/asciidoc.js";
import _asm from "refractor/lang/asm6502.js";
import _aspnet from "refractor/lang/aspnet.js";
import _autohotkey from "refractor/lang/autohotkey.js";
import _autoit from "refractor/lang/autoit.js";
import _bash from "refractor/lang/bash.js";
import _basic from "refractor/lang/basic.js";
import _batch from "refractor/lang/batch.js";
import _bison from "refractor/lang/bison.js";
import _brainfuck from "refractor/lang/brainfuck.js";
import _bro from "refractor/lang/bro.js";
import _c from "refractor/lang/c.js";
import _clike from "refractor/lang/clike.js";
import _clojure from "refractor/lang/clojure.js";
import _coffeescript from "refractor/lang/coffeescript.js";
import _cpp from "refractor/lang/cpp.js";
import _crystal from "refractor/lang/crystal.js";
import _csharp from "refractor/lang/csharp.js";
import _csp from "refractor/lang/csp.js";
import _cssExtras from "refractor/lang/css-extras.js";
import _css from "refractor/lang/css.js";
import _d from "refractor/lang/d.js";
import _dart from "refractor/lang/dart.js";
import _diff from "refractor/lang/diff.js";
import _django from "refractor/lang/django.js";
import _docker from "refractor/lang/docker.js";
import _eiffel from "refractor/lang/eiffel.js";
import _elixir from "refractor/lang/elixir.js";
import _elm from "refractor/lang/elm.js";
import _erb from "refractor/lang/erb.js";
import _erlang from "refractor/lang/erlang.js";
import _flow from "refractor/lang/flow.js";
import _fortran from "refractor/lang/fortran.js";
import _fsharp from "refractor/lang/fsharp.js";
import _gedcom from "refractor/lang/gedcom.js";
import _gherkin from "refractor/lang/gherkin.js";
import _git from "refractor/lang/git.js";
import _glsl from "refractor/lang/glsl.js";
import _go from "refractor/lang/go.js";
import _graphql from "refractor/lang/graphql.js";
import _groovy from "refractor/lang/groovy.js";
import _haml from "refractor/lang/haml.js";
import _handlebars from "refractor/lang/handlebars.js";
import _haskell from "refractor/lang/haskell.js";
import _haxe from "refractor/lang/haxe.js";
import _hpkp from "refractor/lang/hpkp.js";
import _hsts from "refractor/lang/hsts.js";
import _http from "refractor/lang/http.js";
import _ichigojam from "refractor/lang/ichigojam.js";
import _icon from "refractor/lang/icon.js";
import _inform from "refractor/lang/inform7.js";
import _ini from "refractor/lang/ini.js";
import _io from "refractor/lang/io.js";
import _j from "refractor/lang/j.js";
import _java from "refractor/lang/java.js";
import _javascript from "refractor/lang/javascript.js";
import _jolie from "refractor/lang/jolie.js";
import _json from "refractor/lang/json.js";
import _jsx from "refractor/lang/jsx.js";
import _julia from "refractor/lang/julia.js";
import _keyman from "refractor/lang/keyman.js";
import _kotlin from "refractor/lang/kotlin.js";
import _latex from "refractor/lang/latex.js";
import _less from "refractor/lang/less.js";
import _liquid from "refractor/lang/liquid.js";
import _lisp from "refractor/lang/lisp.js";
import _livescript from "refractor/lang/livescript.js";
import _lolcode from "refractor/lang/lolcode.js";
import _lua from "refractor/lang/lua.js";
import _makefile from "refractor/lang/makefile.js";
import _markdown from "refractor/lang/markdown.js";
import _markupTemplating from "refractor/lang/markup-templating.js";
import _markup from "refractor/lang/markup.js";
import _matlab from "refractor/lang/matlab.js";
import _mel from "refractor/lang/mel.js";
import _mizar from "refractor/lang/mizar.js";
import _monkey from "refractor/lang/monkey.js";
import _n4js from "refractor/lang/n4js.js";
import _nasm from "refractor/lang/nasm.js";
import _nginx from "refractor/lang/nginx.js";
import _nim from "refractor/lang/nim.js";
import _nix from "refractor/lang/nix.js";
import _nsis from "refractor/lang/nsis.js";
import _objectivec from "refractor/lang/objectivec.js";
import _ocaml from "refractor/lang/ocaml.js";
import _opencl from "refractor/lang/opencl.js";
import _oz from "refractor/lang/oz.js";
import _parigp from "refractor/lang/parigp.js";
import _parser from "refractor/lang/parser.js";
import _pascal from "refractor/lang/pascal.js";
import _perl from "refractor/lang/perl.js";
import _phpExtras from "refractor/lang/php-extras.js";
import _php from "refractor/lang/php.js";
import _plsql from "refractor/lang/plsql.js";
import _powershell from "refractor/lang/powershell.js";
import _processing from "refractor/lang/processing.js";
import _prolog from "refractor/lang/prolog.js";
import _properties from "refractor/lang/properties.js";
import _protobuf from "refractor/lang/protobuf.js";
import _pug from "refractor/lang/pug.js";
import _puppet from "refractor/lang/puppet.js";
import _pure from "refractor/lang/pure.js";
import _python from "refractor/lang/python.js";
import _q from "refractor/lang/q.js";
import _qore from "refractor/lang/qore.js";
import _r from "refractor/lang/r.js";
import _reason from "refractor/lang/reason.js";
import _renpy from "refractor/lang/renpy.js";
import _rest from "refractor/lang/rest.js";
import _rip from "refractor/lang/rip.js";
import _roboconf from "refractor/lang/roboconf.js";
import _ruby from "refractor/lang/ruby.js";
import _rust from "refractor/lang/rust.js";
import _sas from "refractor/lang/sas.js";
import _sass from "refractor/lang/sass.js";
import _scala from "refractor/lang/scala.js";
import _scheme from "refractor/lang/scheme.js";
import _scss from "refractor/lang/scss.js";
import _smalltalk from "refractor/lang/smalltalk.js";
import _smarty from "refractor/lang/smarty.js";
import _soy from "refractor/lang/soy.js";
import _sql from "refractor/lang/sql.js";
import _stylus from "refractor/lang/stylus.js";
import _swift from "refractor/lang/swift.js";
import _tap from "refractor/lang/tap.js";
import _tcl from "refractor/lang/tcl.js";
import _textile from "refractor/lang/textile.js";
import _tsx from "refractor/lang/tsx.js";
import _tt from "refractor/lang/tt2.js";
import _twig from "refractor/lang/twig.js";
import _typescript from "refractor/lang/typescript.js";
import _vbnet from "refractor/lang/vbnet.js";
import _velocity from "refractor/lang/velocity.js";
import _verilog from "refractor/lang/verilog.js";
import _vhdl from "refractor/lang/vhdl.js";
import _vim from "refractor/lang/vim.js";
import _visualBasic from "refractor/lang/visual-basic.js";
import _wasm from "refractor/lang/wasm.js";
import _wiki from "refractor/lang/wiki.js";
import _xeora from "refractor/lang/xeora.js";
import _xojo from "refractor/lang/xojo.js";
import _xquery from "refractor/lang/xquery.js";
import _yaml from "refractor/lang/yaml.js";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _createLanguageAsyncLoader = _interopRequireDefault(_createLanguageAsyncLoader2);

var _default = {
  abap: (0, _createLanguageAsyncLoader.default)("abap", function () {
    return Promise.resolve().then(function () {
      return _abap;
    });
  }),
  actionscript: (0, _createLanguageAsyncLoader.default)("actionscript", function () {
    return Promise.resolve().then(function () {
      return _actionscript;
    });
  }),
  ada: (0, _createLanguageAsyncLoader.default)("ada", function () {
    return Promise.resolve().then(function () {
      return _ada;
    });
  }),
  apacheconf: (0, _createLanguageAsyncLoader.default)("apacheconf", function () {
    return Promise.resolve().then(function () {
      return _apacheconf;
    });
  }),
  apl: (0, _createLanguageAsyncLoader.default)("apl", function () {
    return Promise.resolve().then(function () {
      return _apl;
    });
  }),
  applescript: (0, _createLanguageAsyncLoader.default)("applescript", function () {
    return Promise.resolve().then(function () {
      return _applescript;
    });
  }),
  arduino: (0, _createLanguageAsyncLoader.default)("arduino", function () {
    return Promise.resolve().then(function () {
      return _arduino;
    });
  }),
  arff: (0, _createLanguageAsyncLoader.default)("arff", function () {
    return Promise.resolve().then(function () {
      return _arff;
    });
  }),
  asciidoc: (0, _createLanguageAsyncLoader.default)("asciidoc", function () {
    return Promise.resolve().then(function () {
      return _asciidoc;
    });
  }),
  asm6502: (0, _createLanguageAsyncLoader.default)("asm6502", function () {
    return Promise.resolve().then(function () {
      return _asm;
    });
  }),
  aspnet: (0, _createLanguageAsyncLoader.default)("aspnet", function () {
    return Promise.resolve().then(function () {
      return _aspnet;
    });
  }),
  autohotkey: (0, _createLanguageAsyncLoader.default)("autohotkey", function () {
    return Promise.resolve().then(function () {
      return _autohotkey;
    });
  }),
  autoit: (0, _createLanguageAsyncLoader.default)("autoit", function () {
    return Promise.resolve().then(function () {
      return _autoit;
    });
  }),
  bash: (0, _createLanguageAsyncLoader.default)("bash", function () {
    return Promise.resolve().then(function () {
      return _bash;
    });
  }),
  basic: (0, _createLanguageAsyncLoader.default)("basic", function () {
    return Promise.resolve().then(function () {
      return _basic;
    });
  }),
  batch: (0, _createLanguageAsyncLoader.default)("batch", function () {
    return Promise.resolve().then(function () {
      return _batch;
    });
  }),
  bison: (0, _createLanguageAsyncLoader.default)("bison", function () {
    return Promise.resolve().then(function () {
      return _bison;
    });
  }),
  brainfuck: (0, _createLanguageAsyncLoader.default)("brainfuck", function () {
    return Promise.resolve().then(function () {
      return _brainfuck;
    });
  }),
  bro: (0, _createLanguageAsyncLoader.default)("bro", function () {
    return Promise.resolve().then(function () {
      return _bro;
    });
  }),
  c: (0, _createLanguageAsyncLoader.default)("c", function () {
    return Promise.resolve().then(function () {
      return _c;
    });
  }),
  clike: (0, _createLanguageAsyncLoader.default)("clike", function () {
    return Promise.resolve().then(function () {
      return _clike;
    });
  }),
  clojure: (0, _createLanguageAsyncLoader.default)("clojure", function () {
    return Promise.resolve().then(function () {
      return _clojure;
    });
  }),
  coffeescript: (0, _createLanguageAsyncLoader.default)("coffeescript", function () {
    return Promise.resolve().then(function () {
      return _coffeescript;
    });
  }),
  cpp: (0, _createLanguageAsyncLoader.default)("cpp", function () {
    return Promise.resolve().then(function () {
      return _cpp;
    });
  }),
  crystal: (0, _createLanguageAsyncLoader.default)("crystal", function () {
    return Promise.resolve().then(function () {
      return _crystal;
    });
  }),
  csharp: (0, _createLanguageAsyncLoader.default)("csharp", function () {
    return Promise.resolve().then(function () {
      return _csharp;
    });
  }),
  csp: (0, _createLanguageAsyncLoader.default)("csp", function () {
    return Promise.resolve().then(function () {
      return _csp;
    });
  }),
  cssExtras: (0, _createLanguageAsyncLoader.default)("cssExtras", function () {
    return Promise.resolve().then(function () {
      return _cssExtras;
    });
  }),
  css: (0, _createLanguageAsyncLoader.default)("css", function () {
    return Promise.resolve().then(function () {
      return _css;
    });
  }),
  d: (0, _createLanguageAsyncLoader.default)("d", function () {
    return Promise.resolve().then(function () {
      return _d;
    });
  }),
  dart: (0, _createLanguageAsyncLoader.default)("dart", function () {
    return Promise.resolve().then(function () {
      return _dart;
    });
  }),
  diff: (0, _createLanguageAsyncLoader.default)("diff", function () {
    return Promise.resolve().then(function () {
      return _diff;
    });
  }),
  django: (0, _createLanguageAsyncLoader.default)("django", function () {
    return Promise.resolve().then(function () {
      return _django;
    });
  }),
  docker: (0, _createLanguageAsyncLoader.default)("docker", function () {
    return Promise.resolve().then(function () {
      return _docker;
    });
  }),
  eiffel: (0, _createLanguageAsyncLoader.default)("eiffel", function () {
    return Promise.resolve().then(function () {
      return _eiffel;
    });
  }),
  elixir: (0, _createLanguageAsyncLoader.default)("elixir", function () {
    return Promise.resolve().then(function () {
      return _elixir;
    });
  }),
  elm: (0, _createLanguageAsyncLoader.default)("elm", function () {
    return Promise.resolve().then(function () {
      return _elm;
    });
  }),
  erb: (0, _createLanguageAsyncLoader.default)("erb", function () {
    return Promise.resolve().then(function () {
      return _erb;
    });
  }),
  erlang: (0, _createLanguageAsyncLoader.default)("erlang", function () {
    return Promise.resolve().then(function () {
      return _erlang;
    });
  }),
  flow: (0, _createLanguageAsyncLoader.default)("flow", function () {
    return Promise.resolve().then(function () {
      return _flow;
    });
  }),
  fortran: (0, _createLanguageAsyncLoader.default)("fortran", function () {
    return Promise.resolve().then(function () {
      return _fortran;
    });
  }),
  fsharp: (0, _createLanguageAsyncLoader.default)("fsharp", function () {
    return Promise.resolve().then(function () {
      return _fsharp;
    });
  }),
  gedcom: (0, _createLanguageAsyncLoader.default)("gedcom", function () {
    return Promise.resolve().then(function () {
      return _gedcom;
    });
  }),
  gherkin: (0, _createLanguageAsyncLoader.default)("gherkin", function () {
    return Promise.resolve().then(function () {
      return _gherkin;
    });
  }),
  git: (0, _createLanguageAsyncLoader.default)("git", function () {
    return Promise.resolve().then(function () {
      return _git;
    });
  }),
  glsl: (0, _createLanguageAsyncLoader.default)("glsl", function () {
    return Promise.resolve().then(function () {
      return _glsl;
    });
  }),
  go: (0, _createLanguageAsyncLoader.default)("go", function () {
    return Promise.resolve().then(function () {
      return _go;
    });
  }),
  graphql: (0, _createLanguageAsyncLoader.default)("graphql", function () {
    return Promise.resolve().then(function () {
      return _graphql;
    });
  }),
  groovy: (0, _createLanguageAsyncLoader.default)("groovy", function () {
    return Promise.resolve().then(function () {
      return _groovy;
    });
  }),
  haml: (0, _createLanguageAsyncLoader.default)("haml", function () {
    return Promise.resolve().then(function () {
      return _haml;
    });
  }),
  handlebars: (0, _createLanguageAsyncLoader.default)("handlebars", function () {
    return Promise.resolve().then(function () {
      return _handlebars;
    });
  }),
  haskell: (0, _createLanguageAsyncLoader.default)("haskell", function () {
    return Promise.resolve().then(function () {
      return _haskell;
    });
  }),
  haxe: (0, _createLanguageAsyncLoader.default)("haxe", function () {
    return Promise.resolve().then(function () {
      return _haxe;
    });
  }),
  hpkp: (0, _createLanguageAsyncLoader.default)("hpkp", function () {
    return Promise.resolve().then(function () {
      return _hpkp;
    });
  }),
  hsts: (0, _createLanguageAsyncLoader.default)("hsts", function () {
    return Promise.resolve().then(function () {
      return _hsts;
    });
  }),
  http: (0, _createLanguageAsyncLoader.default)("http", function () {
    return Promise.resolve().then(function () {
      return _http;
    });
  }),
  ichigojam: (0, _createLanguageAsyncLoader.default)("ichigojam", function () {
    return Promise.resolve().then(function () {
      return _ichigojam;
    });
  }),
  icon: (0, _createLanguageAsyncLoader.default)("icon", function () {
    return Promise.resolve().then(function () {
      return _icon;
    });
  }),
  inform7: (0, _createLanguageAsyncLoader.default)("inform7", function () {
    return Promise.resolve().then(function () {
      return _inform;
    });
  }),
  ini: (0, _createLanguageAsyncLoader.default)("ini", function () {
    return Promise.resolve().then(function () {
      return _ini;
    });
  }),
  io: (0, _createLanguageAsyncLoader.default)("io", function () {
    return Promise.resolve().then(function () {
      return _io;
    });
  }),
  j: (0, _createLanguageAsyncLoader.default)("j", function () {
    return Promise.resolve().then(function () {
      return _j;
    });
  }),
  java: (0, _createLanguageAsyncLoader.default)("java", function () {
    return Promise.resolve().then(function () {
      return _java;
    });
  }),
  javascript: (0, _createLanguageAsyncLoader.default)("javascript", function () {
    return Promise.resolve().then(function () {
      return _javascript;
    });
  }),
  jolie: (0, _createLanguageAsyncLoader.default)("jolie", function () {
    return Promise.resolve().then(function () {
      return _jolie;
    });
  }),
  json: (0, _createLanguageAsyncLoader.default)("json", function () {
    return Promise.resolve().then(function () {
      return _json;
    });
  }),
  jsx: (0, _createLanguageAsyncLoader.default)("jsx", function () {
    return Promise.resolve().then(function () {
      return _jsx;
    });
  }),
  julia: (0, _createLanguageAsyncLoader.default)("julia", function () {
    return Promise.resolve().then(function () {
      return _julia;
    });
  }),
  keyman: (0, _createLanguageAsyncLoader.default)("keyman", function () {
    return Promise.resolve().then(function () {
      return _keyman;
    });
  }),
  kotlin: (0, _createLanguageAsyncLoader.default)("kotlin", function () {
    return Promise.resolve().then(function () {
      return _kotlin;
    });
  }),
  latex: (0, _createLanguageAsyncLoader.default)("latex", function () {
    return Promise.resolve().then(function () {
      return _latex;
    });
  }),
  less: (0, _createLanguageAsyncLoader.default)("less", function () {
    return Promise.resolve().then(function () {
      return _less;
    });
  }),
  liquid: (0, _createLanguageAsyncLoader.default)("liquid", function () {
    return Promise.resolve().then(function () {
      return _liquid;
    });
  }),
  lisp: (0, _createLanguageAsyncLoader.default)("lisp", function () {
    return Promise.resolve().then(function () {
      return _lisp;
    });
  }),
  livescript: (0, _createLanguageAsyncLoader.default)("livescript", function () {
    return Promise.resolve().then(function () {
      return _livescript;
    });
  }),
  lolcode: (0, _createLanguageAsyncLoader.default)("lolcode", function () {
    return Promise.resolve().then(function () {
      return _lolcode;
    });
  }),
  lua: (0, _createLanguageAsyncLoader.default)("lua", function () {
    return Promise.resolve().then(function () {
      return _lua;
    });
  }),
  makefile: (0, _createLanguageAsyncLoader.default)("makefile", function () {
    return Promise.resolve().then(function () {
      return _makefile;
    });
  }),
  markdown: (0, _createLanguageAsyncLoader.default)("markdown", function () {
    return Promise.resolve().then(function () {
      return _markdown;
    });
  }),
  markupTemplating: (0, _createLanguageAsyncLoader.default)("markupTemplating", function () {
    return Promise.resolve().then(function () {
      return _markupTemplating;
    });
  }),
  markup: (0, _createLanguageAsyncLoader.default)("markup", function () {
    return Promise.resolve().then(function () {
      return _markup;
    });
  }),
  matlab: (0, _createLanguageAsyncLoader.default)("matlab", function () {
    return Promise.resolve().then(function () {
      return _matlab;
    });
  }),
  mel: (0, _createLanguageAsyncLoader.default)("mel", function () {
    return Promise.resolve().then(function () {
      return _mel;
    });
  }),
  mizar: (0, _createLanguageAsyncLoader.default)("mizar", function () {
    return Promise.resolve().then(function () {
      return _mizar;
    });
  }),
  monkey: (0, _createLanguageAsyncLoader.default)("monkey", function () {
    return Promise.resolve().then(function () {
      return _monkey;
    });
  }),
  n4js: (0, _createLanguageAsyncLoader.default)("n4js", function () {
    return Promise.resolve().then(function () {
      return _n4js;
    });
  }),
  nasm: (0, _createLanguageAsyncLoader.default)("nasm", function () {
    return Promise.resolve().then(function () {
      return _nasm;
    });
  }),
  nginx: (0, _createLanguageAsyncLoader.default)("nginx", function () {
    return Promise.resolve().then(function () {
      return _nginx;
    });
  }),
  nim: (0, _createLanguageAsyncLoader.default)("nim", function () {
    return Promise.resolve().then(function () {
      return _nim;
    });
  }),
  nix: (0, _createLanguageAsyncLoader.default)("nix", function () {
    return Promise.resolve().then(function () {
      return _nix;
    });
  }),
  nsis: (0, _createLanguageAsyncLoader.default)("nsis", function () {
    return Promise.resolve().then(function () {
      return _nsis;
    });
  }),
  objectivec: (0, _createLanguageAsyncLoader.default)("objectivec", function () {
    return Promise.resolve().then(function () {
      return _objectivec;
    });
  }),
  ocaml: (0, _createLanguageAsyncLoader.default)("ocaml", function () {
    return Promise.resolve().then(function () {
      return _ocaml;
    });
  }),
  opencl: (0, _createLanguageAsyncLoader.default)("opencl", function () {
    return Promise.resolve().then(function () {
      return _opencl;
    });
  }),
  oz: (0, _createLanguageAsyncLoader.default)("oz", function () {
    return Promise.resolve().then(function () {
      return _oz;
    });
  }),
  parigp: (0, _createLanguageAsyncLoader.default)("parigp", function () {
    return Promise.resolve().then(function () {
      return _parigp;
    });
  }),
  parser: (0, _createLanguageAsyncLoader.default)("parser", function () {
    return Promise.resolve().then(function () {
      return _parser;
    });
  }),
  pascal: (0, _createLanguageAsyncLoader.default)("pascal", function () {
    return Promise.resolve().then(function () {
      return _pascal;
    });
  }),
  perl: (0, _createLanguageAsyncLoader.default)("perl", function () {
    return Promise.resolve().then(function () {
      return _perl;
    });
  }),
  phpExtras: (0, _createLanguageAsyncLoader.default)("phpExtras", function () {
    return Promise.resolve().then(function () {
      return _phpExtras;
    });
  }),
  php: (0, _createLanguageAsyncLoader.default)("php", function () {
    return Promise.resolve().then(function () {
      return _php;
    });
  }),
  plsql: (0, _createLanguageAsyncLoader.default)("plsql", function () {
    return Promise.resolve().then(function () {
      return _plsql;
    });
  }),
  powershell: (0, _createLanguageAsyncLoader.default)("powershell", function () {
    return Promise.resolve().then(function () {
      return _powershell;
    });
  }),
  processing: (0, _createLanguageAsyncLoader.default)("processing", function () {
    return Promise.resolve().then(function () {
      return _processing;
    });
  }),
  prolog: (0, _createLanguageAsyncLoader.default)("prolog", function () {
    return Promise.resolve().then(function () {
      return _prolog;
    });
  }),
  properties: (0, _createLanguageAsyncLoader.default)("properties", function () {
    return Promise.resolve().then(function () {
      return _properties;
    });
  }),
  protobuf: (0, _createLanguageAsyncLoader.default)("protobuf", function () {
    return Promise.resolve().then(function () {
      return _protobuf;
    });
  }),
  pug: (0, _createLanguageAsyncLoader.default)("pug", function () {
    return Promise.resolve().then(function () {
      return _pug;
    });
  }),
  puppet: (0, _createLanguageAsyncLoader.default)("puppet", function () {
    return Promise.resolve().then(function () {
      return _puppet;
    });
  }),
  pure: (0, _createLanguageAsyncLoader.default)("pure", function () {
    return Promise.resolve().then(function () {
      return _pure;
    });
  }),
  python: (0, _createLanguageAsyncLoader.default)("python", function () {
    return Promise.resolve().then(function () {
      return _python;
    });
  }),
  q: (0, _createLanguageAsyncLoader.default)("q", function () {
    return Promise.resolve().then(function () {
      return _q;
    });
  }),
  qore: (0, _createLanguageAsyncLoader.default)("qore", function () {
    return Promise.resolve().then(function () {
      return _qore;
    });
  }),
  r: (0, _createLanguageAsyncLoader.default)("r", function () {
    return Promise.resolve().then(function () {
      return _r;
    });
  }),
  reason: (0, _createLanguageAsyncLoader.default)("reason", function () {
    return Promise.resolve().then(function () {
      return _reason;
    });
  }),
  renpy: (0, _createLanguageAsyncLoader.default)("renpy", function () {
    return Promise.resolve().then(function () {
      return _renpy;
    });
  }),
  rest: (0, _createLanguageAsyncLoader.default)("rest", function () {
    return Promise.resolve().then(function () {
      return _rest;
    });
  }),
  rip: (0, _createLanguageAsyncLoader.default)("rip", function () {
    return Promise.resolve().then(function () {
      return _rip;
    });
  }),
  roboconf: (0, _createLanguageAsyncLoader.default)("roboconf", function () {
    return Promise.resolve().then(function () {
      return _roboconf;
    });
  }),
  ruby: (0, _createLanguageAsyncLoader.default)("ruby", function () {
    return Promise.resolve().then(function () {
      return _ruby;
    });
  }),
  rust: (0, _createLanguageAsyncLoader.default)("rust", function () {
    return Promise.resolve().then(function () {
      return _rust;
    });
  }),
  sas: (0, _createLanguageAsyncLoader.default)("sas", function () {
    return Promise.resolve().then(function () {
      return _sas;
    });
  }),
  sass: (0, _createLanguageAsyncLoader.default)("sass", function () {
    return Promise.resolve().then(function () {
      return _sass;
    });
  }),
  scala: (0, _createLanguageAsyncLoader.default)("scala", function () {
    return Promise.resolve().then(function () {
      return _scala;
    });
  }),
  scheme: (0, _createLanguageAsyncLoader.default)("scheme", function () {
    return Promise.resolve().then(function () {
      return _scheme;
    });
  }),
  scss: (0, _createLanguageAsyncLoader.default)("scss", function () {
    return Promise.resolve().then(function () {
      return _scss;
    });
  }),
  smalltalk: (0, _createLanguageAsyncLoader.default)("smalltalk", function () {
    return Promise.resolve().then(function () {
      return _smalltalk;
    });
  }),
  smarty: (0, _createLanguageAsyncLoader.default)("smarty", function () {
    return Promise.resolve().then(function () {
      return _smarty;
    });
  }),
  soy: (0, _createLanguageAsyncLoader.default)("soy", function () {
    return Promise.resolve().then(function () {
      return _soy;
    });
  }),
  sql: (0, _createLanguageAsyncLoader.default)("sql", function () {
    return Promise.resolve().then(function () {
      return _sql;
    });
  }),
  stylus: (0, _createLanguageAsyncLoader.default)("stylus", function () {
    return Promise.resolve().then(function () {
      return _stylus;
    });
  }),
  swift: (0, _createLanguageAsyncLoader.default)("swift", function () {
    return Promise.resolve().then(function () {
      return _swift;
    });
  }),
  tap: (0, _createLanguageAsyncLoader.default)("tap", function () {
    return Promise.resolve().then(function () {
      return _tap;
    });
  }),
  tcl: (0, _createLanguageAsyncLoader.default)("tcl", function () {
    return Promise.resolve().then(function () {
      return _tcl;
    });
  }),
  textile: (0, _createLanguageAsyncLoader.default)("textile", function () {
    return Promise.resolve().then(function () {
      return _textile;
    });
  }),
  tsx: (0, _createLanguageAsyncLoader.default)("tsx", function () {
    return Promise.resolve().then(function () {
      return _tsx;
    });
  }),
  tt2: (0, _createLanguageAsyncLoader.default)("tt2", function () {
    return Promise.resolve().then(function () {
      return _tt;
    });
  }),
  twig: (0, _createLanguageAsyncLoader.default)("twig", function () {
    return Promise.resolve().then(function () {
      return _twig;
    });
  }),
  typescript: (0, _createLanguageAsyncLoader.default)("typescript", function () {
    return Promise.resolve().then(function () {
      return _typescript;
    });
  }),
  vbnet: (0, _createLanguageAsyncLoader.default)("vbnet", function () {
    return Promise.resolve().then(function () {
      return _vbnet;
    });
  }),
  velocity: (0, _createLanguageAsyncLoader.default)("velocity", function () {
    return Promise.resolve().then(function () {
      return _velocity;
    });
  }),
  verilog: (0, _createLanguageAsyncLoader.default)("verilog", function () {
    return Promise.resolve().then(function () {
      return _verilog;
    });
  }),
  vhdl: (0, _createLanguageAsyncLoader.default)("vhdl", function () {
    return Promise.resolve().then(function () {
      return _vhdl;
    });
  }),
  vim: (0, _createLanguageAsyncLoader.default)("vim", function () {
    return Promise.resolve().then(function () {
      return _vim;
    });
  }),
  visualBasic: (0, _createLanguageAsyncLoader.default)("visualBasic", function () {
    return Promise.resolve().then(function () {
      return _visualBasic;
    });
  }),
  wasm: (0, _createLanguageAsyncLoader.default)("wasm", function () {
    return Promise.resolve().then(function () {
      return _wasm;
    });
  }),
  wiki: (0, _createLanguageAsyncLoader.default)("wiki", function () {
    return Promise.resolve().then(function () {
      return _wiki;
    });
  }),
  xeora: (0, _createLanguageAsyncLoader.default)("xeora", function () {
    return Promise.resolve().then(function () {
      return _xeora;
    });
  }),
  xojo: (0, _createLanguageAsyncLoader.default)("xojo", function () {
    return Promise.resolve().then(function () {
      return _xojo;
    });
  }),
  xquery: (0, _createLanguageAsyncLoader.default)("xquery", function () {
    return Promise.resolve().then(function () {
      return _xquery;
    });
  }),
  yaml: (0, _createLanguageAsyncLoader.default)("yaml", function () {
    return Promise.resolve().then(function () {
      return _yaml;
    });
  })
};
exports.default = _default;
export default exports;