import _interopRequireDefault2 from "@babel/runtime/helpers/interopRequireDefault";
import _highlight2 from "./highlight";
import _core2 from "lowlight/lib/core";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _highlight = _interopRequireDefault(_highlight2);

var _core = _interopRequireDefault(_core2);

var SyntaxHighlighter = (0, _highlight.default)(_core.default, {});
SyntaxHighlighter.registerLanguage = _core.default.registerLanguage;
var _default = SyntaxHighlighter;
exports.default = _default;
export default exports;