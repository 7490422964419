import _interopRequireDefault2 from "@babel/runtime/helpers/interopRequireDefault";
import _createLanguageAsyncLoader2 from "./create-language-async-loader";
import _c from "highlight.js/lib/languages/1c";
import _abnf from "highlight.js/lib/languages/abnf";
import _accesslog from "highlight.js/lib/languages/accesslog";
import _actionscript from "highlight.js/lib/languages/actionscript";
import _ada from "highlight.js/lib/languages/ada";
import _angelscript from "highlight.js/lib/languages/angelscript";
import _apache from "highlight.js/lib/languages/apache";
import _applescript from "highlight.js/lib/languages/applescript";
import _arcade from "highlight.js/lib/languages/arcade";
import _arduino from "highlight.js/lib/languages/arduino";
import _armasm from "highlight.js/lib/languages/armasm";
import _asciidoc from "highlight.js/lib/languages/asciidoc";
import _aspectj from "highlight.js/lib/languages/aspectj";
import _autohotkey from "highlight.js/lib/languages/autohotkey";
import _autoit from "highlight.js/lib/languages/autoit";
import _avrasm from "highlight.js/lib/languages/avrasm";
import _awk from "highlight.js/lib/languages/awk";
import _axapta from "highlight.js/lib/languages/axapta";
import _bash from "highlight.js/lib/languages/bash";
import _basic from "highlight.js/lib/languages/basic";
import _bnf from "highlight.js/lib/languages/bnf";
import _brainfuck from "highlight.js/lib/languages/brainfuck";
import _cal from "highlight.js/lib/languages/cal";
import _capnproto from "highlight.js/lib/languages/capnproto";
import _ceylon from "highlight.js/lib/languages/ceylon";
import _clean from "highlight.js/lib/languages/clean";
import _clojureRepl from "highlight.js/lib/languages/clojure-repl";
import _clojure from "highlight.js/lib/languages/clojure";
import _cmake from "highlight.js/lib/languages/cmake";
import _coffeescript from "highlight.js/lib/languages/coffeescript";
import _coq from "highlight.js/lib/languages/coq";
import _cos from "highlight.js/lib/languages/cos";
import _cpp from "highlight.js/lib/languages/cpp";
import _crmsh from "highlight.js/lib/languages/crmsh";
import _crystal from "highlight.js/lib/languages/crystal";
import _cs from "highlight.js/lib/languages/cs";
import _csp from "highlight.js/lib/languages/csp";
import _css from "highlight.js/lib/languages/css";
import _d from "highlight.js/lib/languages/d";
import _dart from "highlight.js/lib/languages/dart";
import _delphi from "highlight.js/lib/languages/delphi";
import _diff from "highlight.js/lib/languages/diff";
import _django from "highlight.js/lib/languages/django";
import _dns from "highlight.js/lib/languages/dns";
import _dockerfile from "highlight.js/lib/languages/dockerfile";
import _dos from "highlight.js/lib/languages/dos";
import _dsconfig from "highlight.js/lib/languages/dsconfig";
import _dts from "highlight.js/lib/languages/dts";
import _dust from "highlight.js/lib/languages/dust";
import _ebnf from "highlight.js/lib/languages/ebnf";
import _elixir from "highlight.js/lib/languages/elixir";
import _elm from "highlight.js/lib/languages/elm";
import _erb from "highlight.js/lib/languages/erb";
import _erlangRepl from "highlight.js/lib/languages/erlang-repl";
import _erlang from "highlight.js/lib/languages/erlang";
import _excel from "highlight.js/lib/languages/excel";
import _fix from "highlight.js/lib/languages/fix";
import _flix from "highlight.js/lib/languages/flix";
import _fortran from "highlight.js/lib/languages/fortran";
import _fsharp from "highlight.js/lib/languages/fsharp";
import _gams from "highlight.js/lib/languages/gams";
import _gauss from "highlight.js/lib/languages/gauss";
import _gcode from "highlight.js/lib/languages/gcode";
import _gherkin from "highlight.js/lib/languages/gherkin";
import _glsl from "highlight.js/lib/languages/glsl";
import _gml from "highlight.js/lib/languages/gml";
import _go from "highlight.js/lib/languages/go";
import _golo from "highlight.js/lib/languages/golo";
import _gradle from "highlight.js/lib/languages/gradle";
import _groovy from "highlight.js/lib/languages/groovy";
import _haml from "highlight.js/lib/languages/haml";
import _handlebars from "highlight.js/lib/languages/handlebars";
import _haskell from "highlight.js/lib/languages/haskell";
import _haxe from "highlight.js/lib/languages/haxe";
import _hsp from "highlight.js/lib/languages/hsp";
import _htmlbars from "highlight.js/lib/languages/htmlbars";
import _http from "highlight.js/lib/languages/http";
import _hy from "highlight.js/lib/languages/hy";
import _inform from "highlight.js/lib/languages/inform7";
import _ini from "highlight.js/lib/languages/ini";
import _irpf from "highlight.js/lib/languages/irpf90";
import _isbl from "highlight.js/lib/languages/isbl";
import _java from "highlight.js/lib/languages/java";
import _javascript from "highlight.js/lib/languages/javascript";
import _jbossCli from "highlight.js/lib/languages/jboss-cli";
import _json from "highlight.js/lib/languages/json";
import _juliaRepl from "highlight.js/lib/languages/julia-repl";
import _julia from "highlight.js/lib/languages/julia";
import _kotlin from "highlight.js/lib/languages/kotlin";
import _lasso from "highlight.js/lib/languages/lasso";
import _ldif from "highlight.js/lib/languages/ldif";
import _leaf from "highlight.js/lib/languages/leaf";
import _less from "highlight.js/lib/languages/less";
import _lisp from "highlight.js/lib/languages/lisp";
import _livecodeserver from "highlight.js/lib/languages/livecodeserver";
import _livescript from "highlight.js/lib/languages/livescript";
import _llvm from "highlight.js/lib/languages/llvm";
import _lsl from "highlight.js/lib/languages/lsl";
import _lua from "highlight.js/lib/languages/lua";
import _makefile from "highlight.js/lib/languages/makefile";
import _markdown from "highlight.js/lib/languages/markdown";
import _mathematica from "highlight.js/lib/languages/mathematica";
import _matlab from "highlight.js/lib/languages/matlab";
import _maxima from "highlight.js/lib/languages/maxima";
import _mel from "highlight.js/lib/languages/mel";
import _mercury from "highlight.js/lib/languages/mercury";
import _mipsasm from "highlight.js/lib/languages/mipsasm";
import _mizar from "highlight.js/lib/languages/mizar";
import _mojolicious from "highlight.js/lib/languages/mojolicious";
import _monkey from "highlight.js/lib/languages/monkey";
import _moonscript from "highlight.js/lib/languages/moonscript";
import _n1ql from "highlight.js/lib/languages/n1ql";
import _nginx from "highlight.js/lib/languages/nginx";
import _nimrod from "highlight.js/lib/languages/nimrod";
import _nix from "highlight.js/lib/languages/nix";
import _nsis from "highlight.js/lib/languages/nsis";
import _objectivec from "highlight.js/lib/languages/objectivec";
import _ocaml from "highlight.js/lib/languages/ocaml";
import _openscad from "highlight.js/lib/languages/openscad";
import _oxygene from "highlight.js/lib/languages/oxygene";
import _parser from "highlight.js/lib/languages/parser3";
import _perl from "highlight.js/lib/languages/perl";
import _pf from "highlight.js/lib/languages/pf";
import _pgsql from "highlight.js/lib/languages/pgsql";
import _php from "highlight.js/lib/languages/php";
import _plaintext from "highlight.js/lib/languages/plaintext";
import _pony from "highlight.js/lib/languages/pony";
import _powershell from "highlight.js/lib/languages/powershell";
import _processing from "highlight.js/lib/languages/processing";
import _profile from "highlight.js/lib/languages/profile";
import _prolog from "highlight.js/lib/languages/prolog";
import _properties from "highlight.js/lib/languages/properties";
import _protobuf from "highlight.js/lib/languages/protobuf";
import _puppet from "highlight.js/lib/languages/puppet";
import _purebasic from "highlight.js/lib/languages/purebasic";
import _python from "highlight.js/lib/languages/python";
import _q from "highlight.js/lib/languages/q";
import _qml from "highlight.js/lib/languages/qml";
import _r from "highlight.js/lib/languages/r";
import _reasonml from "highlight.js/lib/languages/reasonml";
import _rib from "highlight.js/lib/languages/rib";
import _roboconf from "highlight.js/lib/languages/roboconf";
import _routeros from "highlight.js/lib/languages/routeros";
import _rsl from "highlight.js/lib/languages/rsl";
import _ruby from "highlight.js/lib/languages/ruby";
import _ruleslanguage from "highlight.js/lib/languages/ruleslanguage";
import _rust from "highlight.js/lib/languages/rust";
import _sas from "highlight.js/lib/languages/sas";
import _scala from "highlight.js/lib/languages/scala";
import _scheme from "highlight.js/lib/languages/scheme";
import _scilab from "highlight.js/lib/languages/scilab";
import _scss from "highlight.js/lib/languages/scss";
import _shell from "highlight.js/lib/languages/shell";
import _smali from "highlight.js/lib/languages/smali";
import _smalltalk from "highlight.js/lib/languages/smalltalk";
import _sml from "highlight.js/lib/languages/sml";
import _sqf from "highlight.js/lib/languages/sqf";
import _sql from "highlight.js/lib/languages/sql";
import _stan from "highlight.js/lib/languages/stan";
import _stata from "highlight.js/lib/languages/stata";
import _step from "highlight.js/lib/languages/step21";
import _stylus from "highlight.js/lib/languages/stylus";
import _subunit from "highlight.js/lib/languages/subunit";
import _swift from "highlight.js/lib/languages/swift";
import _taggerscript from "highlight.js/lib/languages/taggerscript";
import _tap from "highlight.js/lib/languages/tap";
import _tcl from "highlight.js/lib/languages/tcl";
import _tex from "highlight.js/lib/languages/tex";
import _thrift from "highlight.js/lib/languages/thrift";
import _tp from "highlight.js/lib/languages/tp";
import _twig from "highlight.js/lib/languages/twig";
import _typescript from "highlight.js/lib/languages/typescript";
import _vala from "highlight.js/lib/languages/vala";
import _vbnet from "highlight.js/lib/languages/vbnet";
import _vbscriptHtml from "highlight.js/lib/languages/vbscript-html";
import _vbscript from "highlight.js/lib/languages/vbscript";
import _verilog from "highlight.js/lib/languages/verilog";
import _vhdl from "highlight.js/lib/languages/vhdl";
import _vim from "highlight.js/lib/languages/vim";
import _x86asm from "highlight.js/lib/languages/x86asm";
import _xl from "highlight.js/lib/languages/xl";
import _xml from "highlight.js/lib/languages/xml";
import _xquery from "highlight.js/lib/languages/xquery";
import _yaml from "highlight.js/lib/languages/yaml";
import _zephir from "highlight.js/lib/languages/zephir";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _createLanguageAsyncLoader = _interopRequireDefault(_createLanguageAsyncLoader2);

var _default = {
  oneC: (0, _createLanguageAsyncLoader.default)("oneC", function () {
    return Promise.resolve().then(function () {
      return _c;
    });
  }),
  abnf: (0, _createLanguageAsyncLoader.default)("abnf", function () {
    return Promise.resolve().then(function () {
      return _abnf;
    });
  }),
  accesslog: (0, _createLanguageAsyncLoader.default)("accesslog", function () {
    return Promise.resolve().then(function () {
      return _accesslog;
    });
  }),
  actionscript: (0, _createLanguageAsyncLoader.default)("actionscript", function () {
    return Promise.resolve().then(function () {
      return _actionscript;
    });
  }),
  ada: (0, _createLanguageAsyncLoader.default)("ada", function () {
    return Promise.resolve().then(function () {
      return _ada;
    });
  }),
  angelscript: (0, _createLanguageAsyncLoader.default)("angelscript", function () {
    return Promise.resolve().then(function () {
      return _angelscript;
    });
  }),
  apache: (0, _createLanguageAsyncLoader.default)("apache", function () {
    return Promise.resolve().then(function () {
      return _apache;
    });
  }),
  applescript: (0, _createLanguageAsyncLoader.default)("applescript", function () {
    return Promise.resolve().then(function () {
      return _applescript;
    });
  }),
  arcade: (0, _createLanguageAsyncLoader.default)("arcade", function () {
    return Promise.resolve().then(function () {
      return _arcade;
    });
  }),
  arduino: (0, _createLanguageAsyncLoader.default)("arduino", function () {
    return Promise.resolve().then(function () {
      return _arduino;
    });
  }),
  armasm: (0, _createLanguageAsyncLoader.default)("armasm", function () {
    return Promise.resolve().then(function () {
      return _armasm;
    });
  }),
  asciidoc: (0, _createLanguageAsyncLoader.default)("asciidoc", function () {
    return Promise.resolve().then(function () {
      return _asciidoc;
    });
  }),
  aspectj: (0, _createLanguageAsyncLoader.default)("aspectj", function () {
    return Promise.resolve().then(function () {
      return _aspectj;
    });
  }),
  autohotkey: (0, _createLanguageAsyncLoader.default)("autohotkey", function () {
    return Promise.resolve().then(function () {
      return _autohotkey;
    });
  }),
  autoit: (0, _createLanguageAsyncLoader.default)("autoit", function () {
    return Promise.resolve().then(function () {
      return _autoit;
    });
  }),
  avrasm: (0, _createLanguageAsyncLoader.default)("avrasm", function () {
    return Promise.resolve().then(function () {
      return _avrasm;
    });
  }),
  awk: (0, _createLanguageAsyncLoader.default)("awk", function () {
    return Promise.resolve().then(function () {
      return _awk;
    });
  }),
  axapta: (0, _createLanguageAsyncLoader.default)("axapta", function () {
    return Promise.resolve().then(function () {
      return _axapta;
    });
  }),
  bash: (0, _createLanguageAsyncLoader.default)("bash", function () {
    return Promise.resolve().then(function () {
      return _bash;
    });
  }),
  basic: (0, _createLanguageAsyncLoader.default)("basic", function () {
    return Promise.resolve().then(function () {
      return _basic;
    });
  }),
  bnf: (0, _createLanguageAsyncLoader.default)("bnf", function () {
    return Promise.resolve().then(function () {
      return _bnf;
    });
  }),
  brainfuck: (0, _createLanguageAsyncLoader.default)("brainfuck", function () {
    return Promise.resolve().then(function () {
      return _brainfuck;
    });
  }),
  cal: (0, _createLanguageAsyncLoader.default)("cal", function () {
    return Promise.resolve().then(function () {
      return _cal;
    });
  }),
  capnproto: (0, _createLanguageAsyncLoader.default)("capnproto", function () {
    return Promise.resolve().then(function () {
      return _capnproto;
    });
  }),
  ceylon: (0, _createLanguageAsyncLoader.default)("ceylon", function () {
    return Promise.resolve().then(function () {
      return _ceylon;
    });
  }),
  clean: (0, _createLanguageAsyncLoader.default)("clean", function () {
    return Promise.resolve().then(function () {
      return _clean;
    });
  }),
  clojureRepl: (0, _createLanguageAsyncLoader.default)("clojureRepl", function () {
    return Promise.resolve().then(function () {
      return _clojureRepl;
    });
  }),
  clojure: (0, _createLanguageAsyncLoader.default)("clojure", function () {
    return Promise.resolve().then(function () {
      return _clojure;
    });
  }),
  cmake: (0, _createLanguageAsyncLoader.default)("cmake", function () {
    return Promise.resolve().then(function () {
      return _cmake;
    });
  }),
  coffeescript: (0, _createLanguageAsyncLoader.default)("coffeescript", function () {
    return Promise.resolve().then(function () {
      return _coffeescript;
    });
  }),
  coq: (0, _createLanguageAsyncLoader.default)("coq", function () {
    return Promise.resolve().then(function () {
      return _coq;
    });
  }),
  cos: (0, _createLanguageAsyncLoader.default)("cos", function () {
    return Promise.resolve().then(function () {
      return _cos;
    });
  }),
  cpp: (0, _createLanguageAsyncLoader.default)("cpp", function () {
    return Promise.resolve().then(function () {
      return _cpp;
    });
  }),
  crmsh: (0, _createLanguageAsyncLoader.default)("crmsh", function () {
    return Promise.resolve().then(function () {
      return _crmsh;
    });
  }),
  crystal: (0, _createLanguageAsyncLoader.default)("crystal", function () {
    return Promise.resolve().then(function () {
      return _crystal;
    });
  }),
  cs: (0, _createLanguageAsyncLoader.default)("cs", function () {
    return Promise.resolve().then(function () {
      return _cs;
    });
  }),
  csp: (0, _createLanguageAsyncLoader.default)("csp", function () {
    return Promise.resolve().then(function () {
      return _csp;
    });
  }),
  css: (0, _createLanguageAsyncLoader.default)("css", function () {
    return Promise.resolve().then(function () {
      return _css;
    });
  }),
  d: (0, _createLanguageAsyncLoader.default)("d", function () {
    return Promise.resolve().then(function () {
      return _d;
    });
  }),
  dart: (0, _createLanguageAsyncLoader.default)("dart", function () {
    return Promise.resolve().then(function () {
      return _dart;
    });
  }),
  delphi: (0, _createLanguageAsyncLoader.default)("delphi", function () {
    return Promise.resolve().then(function () {
      return _delphi;
    });
  }),
  diff: (0, _createLanguageAsyncLoader.default)("diff", function () {
    return Promise.resolve().then(function () {
      return _diff;
    });
  }),
  django: (0, _createLanguageAsyncLoader.default)("django", function () {
    return Promise.resolve().then(function () {
      return _django;
    });
  }),
  dns: (0, _createLanguageAsyncLoader.default)("dns", function () {
    return Promise.resolve().then(function () {
      return _dns;
    });
  }),
  dockerfile: (0, _createLanguageAsyncLoader.default)("dockerfile", function () {
    return Promise.resolve().then(function () {
      return _dockerfile;
    });
  }),
  dos: (0, _createLanguageAsyncLoader.default)("dos", function () {
    return Promise.resolve().then(function () {
      return _dos;
    });
  }),
  dsconfig: (0, _createLanguageAsyncLoader.default)("dsconfig", function () {
    return Promise.resolve().then(function () {
      return _dsconfig;
    });
  }),
  dts: (0, _createLanguageAsyncLoader.default)("dts", function () {
    return Promise.resolve().then(function () {
      return _dts;
    });
  }),
  dust: (0, _createLanguageAsyncLoader.default)("dust", function () {
    return Promise.resolve().then(function () {
      return _dust;
    });
  }),
  ebnf: (0, _createLanguageAsyncLoader.default)("ebnf", function () {
    return Promise.resolve().then(function () {
      return _ebnf;
    });
  }),
  elixir: (0, _createLanguageAsyncLoader.default)("elixir", function () {
    return Promise.resolve().then(function () {
      return _elixir;
    });
  }),
  elm: (0, _createLanguageAsyncLoader.default)("elm", function () {
    return Promise.resolve().then(function () {
      return _elm;
    });
  }),
  erb: (0, _createLanguageAsyncLoader.default)("erb", function () {
    return Promise.resolve().then(function () {
      return _erb;
    });
  }),
  erlangRepl: (0, _createLanguageAsyncLoader.default)("erlangRepl", function () {
    return Promise.resolve().then(function () {
      return _erlangRepl;
    });
  }),
  erlang: (0, _createLanguageAsyncLoader.default)("erlang", function () {
    return Promise.resolve().then(function () {
      return _erlang;
    });
  }),
  excel: (0, _createLanguageAsyncLoader.default)("excel", function () {
    return Promise.resolve().then(function () {
      return _excel;
    });
  }),
  fix: (0, _createLanguageAsyncLoader.default)("fix", function () {
    return Promise.resolve().then(function () {
      return _fix;
    });
  }),
  flix: (0, _createLanguageAsyncLoader.default)("flix", function () {
    return Promise.resolve().then(function () {
      return _flix;
    });
  }),
  fortran: (0, _createLanguageAsyncLoader.default)("fortran", function () {
    return Promise.resolve().then(function () {
      return _fortran;
    });
  }),
  fsharp: (0, _createLanguageAsyncLoader.default)("fsharp", function () {
    return Promise.resolve().then(function () {
      return _fsharp;
    });
  }),
  gams: (0, _createLanguageAsyncLoader.default)("gams", function () {
    return Promise.resolve().then(function () {
      return _gams;
    });
  }),
  gauss: (0, _createLanguageAsyncLoader.default)("gauss", function () {
    return Promise.resolve().then(function () {
      return _gauss;
    });
  }),
  gcode: (0, _createLanguageAsyncLoader.default)("gcode", function () {
    return Promise.resolve().then(function () {
      return _gcode;
    });
  }),
  gherkin: (0, _createLanguageAsyncLoader.default)("gherkin", function () {
    return Promise.resolve().then(function () {
      return _gherkin;
    });
  }),
  glsl: (0, _createLanguageAsyncLoader.default)("glsl", function () {
    return Promise.resolve().then(function () {
      return _glsl;
    });
  }),
  gml: (0, _createLanguageAsyncLoader.default)("gml", function () {
    return Promise.resolve().then(function () {
      return _gml;
    });
  }),
  go: (0, _createLanguageAsyncLoader.default)("go", function () {
    return Promise.resolve().then(function () {
      return _go;
    });
  }),
  golo: (0, _createLanguageAsyncLoader.default)("golo", function () {
    return Promise.resolve().then(function () {
      return _golo;
    });
  }),
  gradle: (0, _createLanguageAsyncLoader.default)("gradle", function () {
    return Promise.resolve().then(function () {
      return _gradle;
    });
  }),
  groovy: (0, _createLanguageAsyncLoader.default)("groovy", function () {
    return Promise.resolve().then(function () {
      return _groovy;
    });
  }),
  haml: (0, _createLanguageAsyncLoader.default)("haml", function () {
    return Promise.resolve().then(function () {
      return _haml;
    });
  }),
  handlebars: (0, _createLanguageAsyncLoader.default)("handlebars", function () {
    return Promise.resolve().then(function () {
      return _handlebars;
    });
  }),
  haskell: (0, _createLanguageAsyncLoader.default)("haskell", function () {
    return Promise.resolve().then(function () {
      return _haskell;
    });
  }),
  haxe: (0, _createLanguageAsyncLoader.default)("haxe", function () {
    return Promise.resolve().then(function () {
      return _haxe;
    });
  }),
  hsp: (0, _createLanguageAsyncLoader.default)("hsp", function () {
    return Promise.resolve().then(function () {
      return _hsp;
    });
  }),
  htmlbars: (0, _createLanguageAsyncLoader.default)("htmlbars", function () {
    return Promise.resolve().then(function () {
      return _htmlbars;
    });
  }),
  http: (0, _createLanguageAsyncLoader.default)("http", function () {
    return Promise.resolve().then(function () {
      return _http;
    });
  }),
  hy: (0, _createLanguageAsyncLoader.default)("hy", function () {
    return Promise.resolve().then(function () {
      return _hy;
    });
  }),
  inform7: (0, _createLanguageAsyncLoader.default)("inform7", function () {
    return Promise.resolve().then(function () {
      return _inform;
    });
  }),
  ini: (0, _createLanguageAsyncLoader.default)("ini", function () {
    return Promise.resolve().then(function () {
      return _ini;
    });
  }),
  irpf90: (0, _createLanguageAsyncLoader.default)("irpf90", function () {
    return Promise.resolve().then(function () {
      return _irpf;
    });
  }),
  isbl: (0, _createLanguageAsyncLoader.default)("isbl", function () {
    return Promise.resolve().then(function () {
      return _isbl;
    });
  }),
  java: (0, _createLanguageAsyncLoader.default)("java", function () {
    return Promise.resolve().then(function () {
      return _java;
    });
  }),
  javascript: (0, _createLanguageAsyncLoader.default)("javascript", function () {
    return Promise.resolve().then(function () {
      return _javascript;
    });
  }),
  jbossCli: (0, _createLanguageAsyncLoader.default)("jbossCli", function () {
    return Promise.resolve().then(function () {
      return _jbossCli;
    });
  }),
  json: (0, _createLanguageAsyncLoader.default)("json", function () {
    return Promise.resolve().then(function () {
      return _json;
    });
  }),
  juliaRepl: (0, _createLanguageAsyncLoader.default)("juliaRepl", function () {
    return Promise.resolve().then(function () {
      return _juliaRepl;
    });
  }),
  julia: (0, _createLanguageAsyncLoader.default)("julia", function () {
    return Promise.resolve().then(function () {
      return _julia;
    });
  }),
  kotlin: (0, _createLanguageAsyncLoader.default)("kotlin", function () {
    return Promise.resolve().then(function () {
      return _kotlin;
    });
  }),
  lasso: (0, _createLanguageAsyncLoader.default)("lasso", function () {
    return Promise.resolve().then(function () {
      return _lasso;
    });
  }),
  ldif: (0, _createLanguageAsyncLoader.default)("ldif", function () {
    return Promise.resolve().then(function () {
      return _ldif;
    });
  }),
  leaf: (0, _createLanguageAsyncLoader.default)("leaf", function () {
    return Promise.resolve().then(function () {
      return _leaf;
    });
  }),
  less: (0, _createLanguageAsyncLoader.default)("less", function () {
    return Promise.resolve().then(function () {
      return _less;
    });
  }),
  lisp: (0, _createLanguageAsyncLoader.default)("lisp", function () {
    return Promise.resolve().then(function () {
      return _lisp;
    });
  }),
  livecodeserver: (0, _createLanguageAsyncLoader.default)("livecodeserver", function () {
    return Promise.resolve().then(function () {
      return _livecodeserver;
    });
  }),
  livescript: (0, _createLanguageAsyncLoader.default)("livescript", function () {
    return Promise.resolve().then(function () {
      return _livescript;
    });
  }),
  llvm: (0, _createLanguageAsyncLoader.default)("llvm", function () {
    return Promise.resolve().then(function () {
      return _llvm;
    });
  }),
  lsl: (0, _createLanguageAsyncLoader.default)("lsl", function () {
    return Promise.resolve().then(function () {
      return _lsl;
    });
  }),
  lua: (0, _createLanguageAsyncLoader.default)("lua", function () {
    return Promise.resolve().then(function () {
      return _lua;
    });
  }),
  makefile: (0, _createLanguageAsyncLoader.default)("makefile", function () {
    return Promise.resolve().then(function () {
      return _makefile;
    });
  }),
  markdown: (0, _createLanguageAsyncLoader.default)("markdown", function () {
    return Promise.resolve().then(function () {
      return _markdown;
    });
  }),
  mathematica: (0, _createLanguageAsyncLoader.default)("mathematica", function () {
    return Promise.resolve().then(function () {
      return _mathematica;
    });
  }),
  matlab: (0, _createLanguageAsyncLoader.default)("matlab", function () {
    return Promise.resolve().then(function () {
      return _matlab;
    });
  }),
  maxima: (0, _createLanguageAsyncLoader.default)("maxima", function () {
    return Promise.resolve().then(function () {
      return _maxima;
    });
  }),
  mel: (0, _createLanguageAsyncLoader.default)("mel", function () {
    return Promise.resolve().then(function () {
      return _mel;
    });
  }),
  mercury: (0, _createLanguageAsyncLoader.default)("mercury", function () {
    return Promise.resolve().then(function () {
      return _mercury;
    });
  }),
  mipsasm: (0, _createLanguageAsyncLoader.default)("mipsasm", function () {
    return Promise.resolve().then(function () {
      return _mipsasm;
    });
  }),
  mizar: (0, _createLanguageAsyncLoader.default)("mizar", function () {
    return Promise.resolve().then(function () {
      return _mizar;
    });
  }),
  mojolicious: (0, _createLanguageAsyncLoader.default)("mojolicious", function () {
    return Promise.resolve().then(function () {
      return _mojolicious;
    });
  }),
  monkey: (0, _createLanguageAsyncLoader.default)("monkey", function () {
    return Promise.resolve().then(function () {
      return _monkey;
    });
  }),
  moonscript: (0, _createLanguageAsyncLoader.default)("moonscript", function () {
    return Promise.resolve().then(function () {
      return _moonscript;
    });
  }),
  n1ql: (0, _createLanguageAsyncLoader.default)("n1ql", function () {
    return Promise.resolve().then(function () {
      return _n1ql;
    });
  }),
  nginx: (0, _createLanguageAsyncLoader.default)("nginx", function () {
    return Promise.resolve().then(function () {
      return _nginx;
    });
  }),
  nimrod: (0, _createLanguageAsyncLoader.default)("nimrod", function () {
    return Promise.resolve().then(function () {
      return _nimrod;
    });
  }),
  nix: (0, _createLanguageAsyncLoader.default)("nix", function () {
    return Promise.resolve().then(function () {
      return _nix;
    });
  }),
  nsis: (0, _createLanguageAsyncLoader.default)("nsis", function () {
    return Promise.resolve().then(function () {
      return _nsis;
    });
  }),
  objectivec: (0, _createLanguageAsyncLoader.default)("objectivec", function () {
    return Promise.resolve().then(function () {
      return _objectivec;
    });
  }),
  ocaml: (0, _createLanguageAsyncLoader.default)("ocaml", function () {
    return Promise.resolve().then(function () {
      return _ocaml;
    });
  }),
  openscad: (0, _createLanguageAsyncLoader.default)("openscad", function () {
    return Promise.resolve().then(function () {
      return _openscad;
    });
  }),
  oxygene: (0, _createLanguageAsyncLoader.default)("oxygene", function () {
    return Promise.resolve().then(function () {
      return _oxygene;
    });
  }),
  parser3: (0, _createLanguageAsyncLoader.default)("parser3", function () {
    return Promise.resolve().then(function () {
      return _parser;
    });
  }),
  perl: (0, _createLanguageAsyncLoader.default)("perl", function () {
    return Promise.resolve().then(function () {
      return _perl;
    });
  }),
  pf: (0, _createLanguageAsyncLoader.default)("pf", function () {
    return Promise.resolve().then(function () {
      return _pf;
    });
  }),
  pgsql: (0, _createLanguageAsyncLoader.default)("pgsql", function () {
    return Promise.resolve().then(function () {
      return _pgsql;
    });
  }),
  php: (0, _createLanguageAsyncLoader.default)("php", function () {
    return Promise.resolve().then(function () {
      return _php;
    });
  }),
  plaintext: (0, _createLanguageAsyncLoader.default)("plaintext", function () {
    return Promise.resolve().then(function () {
      return _plaintext;
    });
  }),
  pony: (0, _createLanguageAsyncLoader.default)("pony", function () {
    return Promise.resolve().then(function () {
      return _pony;
    });
  }),
  powershell: (0, _createLanguageAsyncLoader.default)("powershell", function () {
    return Promise.resolve().then(function () {
      return _powershell;
    });
  }),
  processing: (0, _createLanguageAsyncLoader.default)("processing", function () {
    return Promise.resolve().then(function () {
      return _processing;
    });
  }),
  profile: (0, _createLanguageAsyncLoader.default)("profile", function () {
    return Promise.resolve().then(function () {
      return _profile;
    });
  }),
  prolog: (0, _createLanguageAsyncLoader.default)("prolog", function () {
    return Promise.resolve().then(function () {
      return _prolog;
    });
  }),
  properties: (0, _createLanguageAsyncLoader.default)("properties", function () {
    return Promise.resolve().then(function () {
      return _properties;
    });
  }),
  protobuf: (0, _createLanguageAsyncLoader.default)("protobuf", function () {
    return Promise.resolve().then(function () {
      return _protobuf;
    });
  }),
  puppet: (0, _createLanguageAsyncLoader.default)("puppet", function () {
    return Promise.resolve().then(function () {
      return _puppet;
    });
  }),
  purebasic: (0, _createLanguageAsyncLoader.default)("purebasic", function () {
    return Promise.resolve().then(function () {
      return _purebasic;
    });
  }),
  python: (0, _createLanguageAsyncLoader.default)("python", function () {
    return Promise.resolve().then(function () {
      return _python;
    });
  }),
  q: (0, _createLanguageAsyncLoader.default)("q", function () {
    return Promise.resolve().then(function () {
      return _q;
    });
  }),
  qml: (0, _createLanguageAsyncLoader.default)("qml", function () {
    return Promise.resolve().then(function () {
      return _qml;
    });
  }),
  r: (0, _createLanguageAsyncLoader.default)("r", function () {
    return Promise.resolve().then(function () {
      return _r;
    });
  }),
  reasonml: (0, _createLanguageAsyncLoader.default)("reasonml", function () {
    return Promise.resolve().then(function () {
      return _reasonml;
    });
  }),
  rib: (0, _createLanguageAsyncLoader.default)("rib", function () {
    return Promise.resolve().then(function () {
      return _rib;
    });
  }),
  roboconf: (0, _createLanguageAsyncLoader.default)("roboconf", function () {
    return Promise.resolve().then(function () {
      return _roboconf;
    });
  }),
  routeros: (0, _createLanguageAsyncLoader.default)("routeros", function () {
    return Promise.resolve().then(function () {
      return _routeros;
    });
  }),
  rsl: (0, _createLanguageAsyncLoader.default)("rsl", function () {
    return Promise.resolve().then(function () {
      return _rsl;
    });
  }),
  ruby: (0, _createLanguageAsyncLoader.default)("ruby", function () {
    return Promise.resolve().then(function () {
      return _ruby;
    });
  }),
  ruleslanguage: (0, _createLanguageAsyncLoader.default)("ruleslanguage", function () {
    return Promise.resolve().then(function () {
      return _ruleslanguage;
    });
  }),
  rust: (0, _createLanguageAsyncLoader.default)("rust", function () {
    return Promise.resolve().then(function () {
      return _rust;
    });
  }),
  sas: (0, _createLanguageAsyncLoader.default)("sas", function () {
    return Promise.resolve().then(function () {
      return _sas;
    });
  }),
  scala: (0, _createLanguageAsyncLoader.default)("scala", function () {
    return Promise.resolve().then(function () {
      return _scala;
    });
  }),
  scheme: (0, _createLanguageAsyncLoader.default)("scheme", function () {
    return Promise.resolve().then(function () {
      return _scheme;
    });
  }),
  scilab: (0, _createLanguageAsyncLoader.default)("scilab", function () {
    return Promise.resolve().then(function () {
      return _scilab;
    });
  }),
  scss: (0, _createLanguageAsyncLoader.default)("scss", function () {
    return Promise.resolve().then(function () {
      return _scss;
    });
  }),
  shell: (0, _createLanguageAsyncLoader.default)("shell", function () {
    return Promise.resolve().then(function () {
      return _shell;
    });
  }),
  smali: (0, _createLanguageAsyncLoader.default)("smali", function () {
    return Promise.resolve().then(function () {
      return _smali;
    });
  }),
  smalltalk: (0, _createLanguageAsyncLoader.default)("smalltalk", function () {
    return Promise.resolve().then(function () {
      return _smalltalk;
    });
  }),
  sml: (0, _createLanguageAsyncLoader.default)("sml", function () {
    return Promise.resolve().then(function () {
      return _sml;
    });
  }),
  sqf: (0, _createLanguageAsyncLoader.default)("sqf", function () {
    return Promise.resolve().then(function () {
      return _sqf;
    });
  }),
  sql: (0, _createLanguageAsyncLoader.default)("sql", function () {
    return Promise.resolve().then(function () {
      return _sql;
    });
  }),
  stan: (0, _createLanguageAsyncLoader.default)("stan", function () {
    return Promise.resolve().then(function () {
      return _stan;
    });
  }),
  stata: (0, _createLanguageAsyncLoader.default)("stata", function () {
    return Promise.resolve().then(function () {
      return _stata;
    });
  }),
  step21: (0, _createLanguageAsyncLoader.default)("step21", function () {
    return Promise.resolve().then(function () {
      return _step;
    });
  }),
  stylus: (0, _createLanguageAsyncLoader.default)("stylus", function () {
    return Promise.resolve().then(function () {
      return _stylus;
    });
  }),
  subunit: (0, _createLanguageAsyncLoader.default)("subunit", function () {
    return Promise.resolve().then(function () {
      return _subunit;
    });
  }),
  swift: (0, _createLanguageAsyncLoader.default)("swift", function () {
    return Promise.resolve().then(function () {
      return _swift;
    });
  }),
  taggerscript: (0, _createLanguageAsyncLoader.default)("taggerscript", function () {
    return Promise.resolve().then(function () {
      return _taggerscript;
    });
  }),
  tap: (0, _createLanguageAsyncLoader.default)("tap", function () {
    return Promise.resolve().then(function () {
      return _tap;
    });
  }),
  tcl: (0, _createLanguageAsyncLoader.default)("tcl", function () {
    return Promise.resolve().then(function () {
      return _tcl;
    });
  }),
  tex: (0, _createLanguageAsyncLoader.default)("tex", function () {
    return Promise.resolve().then(function () {
      return _tex;
    });
  }),
  thrift: (0, _createLanguageAsyncLoader.default)("thrift", function () {
    return Promise.resolve().then(function () {
      return _thrift;
    });
  }),
  tp: (0, _createLanguageAsyncLoader.default)("tp", function () {
    return Promise.resolve().then(function () {
      return _tp;
    });
  }),
  twig: (0, _createLanguageAsyncLoader.default)("twig", function () {
    return Promise.resolve().then(function () {
      return _twig;
    });
  }),
  typescript: (0, _createLanguageAsyncLoader.default)("typescript", function () {
    return Promise.resolve().then(function () {
      return _typescript;
    });
  }),
  vala: (0, _createLanguageAsyncLoader.default)("vala", function () {
    return Promise.resolve().then(function () {
      return _vala;
    });
  }),
  vbnet: (0, _createLanguageAsyncLoader.default)("vbnet", function () {
    return Promise.resolve().then(function () {
      return _vbnet;
    });
  }),
  vbscriptHtml: (0, _createLanguageAsyncLoader.default)("vbscriptHtml", function () {
    return Promise.resolve().then(function () {
      return _vbscriptHtml;
    });
  }),
  vbscript: (0, _createLanguageAsyncLoader.default)("vbscript", function () {
    return Promise.resolve().then(function () {
      return _vbscript;
    });
  }),
  verilog: (0, _createLanguageAsyncLoader.default)("verilog", function () {
    return Promise.resolve().then(function () {
      return _verilog;
    });
  }),
  vhdl: (0, _createLanguageAsyncLoader.default)("vhdl", function () {
    return Promise.resolve().then(function () {
      return _vhdl;
    });
  }),
  vim: (0, _createLanguageAsyncLoader.default)("vim", function () {
    return Promise.resolve().then(function () {
      return _vim;
    });
  }),
  x86asm: (0, _createLanguageAsyncLoader.default)("x86asm", function () {
    return Promise.resolve().then(function () {
      return _x86asm;
    });
  }),
  xl: (0, _createLanguageAsyncLoader.default)("xl", function () {
    return Promise.resolve().then(function () {
      return _xl;
    });
  }),
  xml: (0, _createLanguageAsyncLoader.default)("xml", function () {
    return Promise.resolve().then(function () {
      return _xml;
    });
  }),
  xquery: (0, _createLanguageAsyncLoader.default)("xquery", function () {
    return Promise.resolve().then(function () {
      return _xquery;
    });
  }),
  yaml: (0, _createLanguageAsyncLoader.default)("yaml", function () {
    return Promise.resolve().then(function () {
      return _yaml;
    });
  }),
  zephir: (0, _createLanguageAsyncLoader.default)("zephir", function () {
    return Promise.resolve().then(function () {
      return _zephir;
    });
  })
};
exports.default = _default;
export default exports;