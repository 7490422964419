import _interopRequireDefault2 from "@babel/runtime/helpers/interopRequireDefault";
import _highlight2 from "./highlight";
import _prism2 from "./styles/prism/prism";
import _refractor2 from "refractor";
import _supportedLanguages2 from "./languages/prism/supported-languages";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _highlight = _interopRequireDefault(_highlight2);

var _prism = _interopRequireDefault(_prism2);

var _refractor = _interopRequireDefault(_refractor2);

var _supportedLanguages = _interopRequireDefault(_supportedLanguages2);

var highlighter = (0, _highlight.default)(_refractor.default, _prism.default);
highlighter.supportedLanguages = _supportedLanguages.default;
var _default = highlighter;
exports.default = _default;
export default exports;