import _interopRequireDefault2 from "@babel/runtime/helpers/interopRequireDefault";
import _highlight2 from "./highlight";
import _defaultStyle2 from "./styles/hljs/default-style";
import _lowlight2 from "lowlight";
import _supportedLanguages2 from "./languages/hljs/supported-languages";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _highlight = _interopRequireDefault(_highlight2);

var _defaultStyle = _interopRequireDefault(_defaultStyle2);

var _lowlight = _interopRequireDefault(_lowlight2);

var _supportedLanguages = _interopRequireDefault(_supportedLanguages2);

var highlighter = (0, _highlight.default)(_lowlight.default, _defaultStyle.default);
highlighter.supportedLanguages = _supportedLanguages.default;
var _default = highlighter;
exports.default = _default;
export default exports;