import _interopRequireDefault2 from "@babel/runtime/helpers/interopRequireDefault";
import _asyncSyntaxHighlighter2 from "./async-syntax-highlighter";
import _supportedLanguages2 from "./languages/prism/supported-languages";
import _refractor from "refractor";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _asyncSyntaxHighlighter = _interopRequireDefault(_asyncSyntaxHighlighter2);

var _supportedLanguages = _interopRequireDefault(_supportedLanguages2);

var _default = (0, _asyncSyntaxHighlighter.default)({
  loader: function loader() {
    return Promise.resolve().then(function () {
      return _refractor;
    }).then(function (module) {
      // Webpack 3 returns module.exports as default as module, but webpack 4 returns module.exports as module.default
      return module.default || module;
    });
  },
  noAsyncLoadingLanguages: true,
  supportedLanguages: _supportedLanguages.default
});

exports.default = _default;
export default exports;